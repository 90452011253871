import {
  DataGrid,
  GridToolbarExport,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  URL_GETALLORDER,
  URL_GETEVENTS_FOR_TICKETMASTER,
  URL_GET_ALL_REGISTRATION,
  URL_GET_PROFILE_PHOTO,
  URL_GET_QR_PDF,
  URL_GET_REGIDTRATION_QR,
  URL_UPLOADIMAGE,
} from "../api/constants";
import Leftnav from "./leftNav/Leftnav";
import Navbar from "./navbar";
import tick from "../images/tiick-unscreen.gif";
import Button from "@mui/material/Button";
import Alert from "./alert/alert";
import Camera from "./Camera";
import Cookies from "js-cookie";
import Print from "./ReactToPrint";
import Loader from "./Loader";

function OnSpotBooking() {
  // setTimeout(() => {
  //   setalert(false);
  // }, 5000);

  const [tableData, settableData] = useState([]);
  const [registrationArr, setregistrationArr] = useState([]);
  const [showpopup, setshowpopup] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [askcompany, setaskcompany] = useState("");

  const [alert, setalert] = useState(false);
  const [alertmsg, setalertmsg] = useState("");
  const [printPopup, setprintPopup] = useState(false);
  const [profileImg, setprofileImg] = useState("");
  const [printingArr, setprintingArr] = useState([]);
  const [Regshowpopup, setRegshowpopup] = useState(false);
  const [thisQrcode, setthisQrcode] = useState("");
  const [registrationmcode, setregistrationmcode] = useState("");
  const [camerapopup, setcamerapopup] = useState(false);
  const [regisPopup, setregisPopup] = useState(false);
  const [registrationDetailsForQR, setregistrationDetailsForQR] = useState([]);
  const [capturedImage, setcapturedImage] = useState("");
  const [Printer, setprinter] = useState(false);
  const [RegPrinter, setRegPrinter] = useState(false);
  const [capturedRegImage, setcapturedRegImage] = useState("");
  const [thisRegData, setthisRegData] = useState([]);
  const [printwithImg, setprintwithImg] = useState(false);
  const [AskForImage, setAskForImage] = useState(false);
  const [uploadfiledisable, setuploadfiledisable] = useState(true);
  const [hideprintbtn, sethideprintbtn] = useState(false);
  const [isBookLoading, setIsBookLoading] = useState(false);
  const [isRegLoading, setIsRegLoading] = useState(false);

  const columns = [
    // { field: 'id', headerName: 'ID' },
    {
      field: "PRINT",
      headerName: "PRINT",
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return params.row.remark === "CONFIRMED" ? (
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => printQR(params.row)}
          >
            Print QR
          </Button>
        ) : (
          ""
        );
      },
    },
    { field: "eventName", headerName: "Event Name", width: 150 },
    { field: "clientName", headerName: "Client Name", width: 150 },
    { field: "ticketName", headerName: "Ticket Name", width: 150 },
    { field: "strCompanyName", headerName: "Print Company", width: 220 },
    { field: "ticketType", headerName: "Ticket Type", width: 150 },
    { field: "remark", headerName: "Remark", width: 150 },

    {
      field: "icode",
      headerName: "Scanned",
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return params.row.icode === "IN" ? (
          <img style={{ height: "80px", marginLeft: "-30px" }} src={tick} />
        ) : (
          <img
            style={{ height: "30px", width: "50px" }}
            src="https://th.bing.com/th/id/OIP.pYx4niZBEXZvzCYXPDQTLQHaHa?w=173&h=180&c=7&r=0&o=5&pid=1.7"
          />
        );
      },
    },
    { field: "pcQuantity", headerName: "Quantity", width: 150 },

    {
      field: "billDate",
      headerName: "Booking Date",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return params.row.billDate.replace("T", " ");
      },
    },

    { field: "balanceAmount", headerName: " Amount", width: 150 },
    {
      field: "Action",
      headerName: "QR CODE",
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return params.row.remark === "CONFIRMED" ? (
          <Button variant="contained" color="primary" onClick={edit}>
            QR CODE
          </Button>
        ) : (
          ""
        );
      },
    },
  ];

  var registrationcolumns = [
    {
      field: "View Event",
      headerName: "QR Code",
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Button
            onClick={(e) => viewQR(params.row)}
            variant="contained"
            color="primary"
          >
            {" "}
            Qr Code
          </Button>
        );
      },
    },
    { field: "name", headerName: "Name" },
    { field: "surname", headerName: "Surname", width: 150 },
    { field: "gender", headerName: "Gender", width: 150 },
    { field: "email", headerName: "Email", width: 150 },
    { field: "jobTitle", headerName: "Job Title", width: 150 },
    {
      field: "companyOrganisation",
      headerName: "Company / Organisation",
      width: 150,
    },
    { field: "workAddress", headerName: "Work Address", width: 150 },
    { field: "workPhone", headerName: "Work Phone", width: 150 },
    { field: "homeAddress", headerName: "Home Address", width: 150 },
    { field: "homePhone", headerName: "Home Phone", width: 150 },
    { field: "website", headerName: "Website", width: 150 },
    { field: "cellPhone", headerName: "Cell Phone", width: 150 },

    { field: "blog", headerName: "Blog", width: 150 },
    { field: "company", headerName: "Print Company", width: 150 },
  ];

  const viewQR = (item) => {
    sethideprintbtn(false);
    setthisRegData(item);
    setregistrationmcode(item.mcode);
    setRegshowpopup(true);
    console.log(item);
    setregistrationDetailsForQR(item);
    var data = JSON.stringify({
      rowpos: item.strRegistrationID,
    });

    var config = {
      method: "post",
      url: URL_GET_REGIDTRATION_QR,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data.data.path);
        setthisQrcode(response.data.data.path);
      })
      .catch(function (error) {
        console.log(error);
      });

    // upload image
  };

  var onspot = [];
  var ticketevents = "";
  var allregistrationns = [];
  useEffect(() => {
    setIsRegLoading(true);
    setIsBookLoading(true);

    var usertyp = Cookies.get("userType");
    var loginmcode = Cookies.get("orgmcode");

    if (usertyp === "TICKETMASTER") {
      console.log("ticket master");
      // filter filter unique ticket _master
      var data = JSON.stringify({
        Mcode: loginmcode,
      });

      var config = {
        method: "post",
        url: URL_GETEVENTS_FOR_TICKETMASTER,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config).then(function (response) {
        console.log(response.data.data.event_id);
        ticketevents = response.data.data.event_id;
      });
    }

    var data = JSON.stringify({
      EventID: "",
    });

    var config = {
      method: "post",
      url: URL_GET_ALL_REGISTRATION,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    console.log(data)

    axios(config)
      .then(function (response) {
        // console.log(response)
        if (response.data.isSuccess) {
          if (usertyp == "TICKETMASTER") {
            allregistrationns = [];
            var eventIds = ticketevents?.split(",");

            for (var i = 0; i < response.data.data.length; i++) {
              if (
                eventIds.some((id) =>
                  response.data.data[i].eventID.includes(id)
                )
              ) {
                allregistrationns.push(response.data.data[i]);
              }
            }
            setregistrationArr(allregistrationns);
            setIsRegLoading(false);
          } else {
            setregistrationArr(response?.data.data);
            setIsRegLoading(false);
          }
        } else {
          setregistrationArr([]);
          setIsRegLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    var config = {
      method: "post",
      url: URL_GETALLORDER,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        // console.log(response?.data.data)
        var mydata = response?.data.data;
        var newitem = mydata?.filter((item) => {
          return (
            item.strInOut === "ONSPOT" ||
            item.strInOut === "ONSPOT_REGISTRATION"
          );
        });
        // console.log(newitem)

        // console.log(ticketevents)
        if (usertyp == "TICKETMASTER") {
          mydata = [];
          var eventIds = ticketevents?.split(",");
          console.log(newitem);
          for (var i = 0; i < newitem.length; i++) {
            if (eventIds.some((id) => newitem[i].eventRowpos.includes(id))) {
              mydata.push(response.data.data[i]);
            }
          }
          settableData(mydata);
          setIsBookLoading(false);
        } else {
          settableData(newitem);
          setIsBookLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    // check if there is  a ticket master
  }, []);

  const edit = () => {
    setshowpopup(true);
  };

  // print qr

  const printQR = (item) => {
    setprintPopup(true);
    setshowpopup(false);
    setaskcompany("");
    setprintingArr(item);
    console.log(item);
  };

  const closesetprintPopup = () => {
    setprintPopup(false);
    setaskcompany("");
    setprintingArr([]);
    setprinter(false);
    // window.location.reload()
    sethideprintbtn(false);
  };

  const handlePhotoPrint = (e) => {
    console.log(askcompany);
    console.log(e.target.files[0]);
    setprofileImg(e.target.files[0]);
    setuploadfiledisable(false);
  };

  const UploadSelectedPhoto = () => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("File", profileImg);
    data.append("Type", "ORDER");
    data.append("Id", printingArr.party);
    data.append("SubType", "ProfilePhoto");

    var config = {
      method: "post",
      url: URL_UPLOADIMAGE,
      headers: {},
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response?.data.isSuccess);
        if (response?.data.isSuccess == true) {
          setalert(true);
          setalertmsg("File Uploaded Sccessfully !");
          setAskForImage(false);
          setuploadfiledisable(true);
        } else {
          setalert(true);
          setalertmsg(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const captureimage = () => {
    setcamerapopup(true);
  };

  // print pdf first save img to db and then print in PDF

  const handlereactPrint = async () => {
    setAskForImage(false);

    var data = JSON.stringify({
      Media_ID: printingArr.party,
    });

    var config = {
      method: "post",
      url: URL_GET_PROFILE_PHOTO,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios(config)
      .then(function (response) {
        setcapturedImage(response?.data.data[0].path);
        console.log(capturedImage);
        setprinter(true);
      })
      .catch(function (error) {
        console.log(error);
      });
    setprinter(true);
    sethideprintbtn(true);
  };

  const printTicketPDF = () => {
    var data = JSON.stringify({
      Media_ID: printingArr.party,
    });

    var config = {
      method: "post",
      url: URL_GET_PROFILE_PHOTO,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response?.data.data[0].path);
        setcapturedImage(response?.data.data[0].path);
      })
      .catch(function (error) {
        console.log(error);
      });

    var data = JSON.stringify({
      MCODE: printingArr.party,
      QRCodePath: printingArr.qrCodePath,
      strTicketID: printingArr.strTicketID,
      strCompanyName: askcompany,
      strBannerPath: capturedImage,
    });

    var config = {
      method: "post",
      url: URL_GET_QR_PDF,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response?.data);
        if (response?.data.isSuccess === true) {
          setprofileImg("");
          console.log(response.data.data);
          window.open(response?.data.data, "_blank");
        } else {
          setalert(true);
          setalertmsg(response?.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleRegisterCamera = () => {
    setregisPopup(true);
  };

  // const handleifImage =()=>{
  //   setprintwithImg(false)
  // }

  const printThisQR = () => {
    console.log(registrationDetailsForQR.strRegistrationCode);
    console.log(thisQrcode);
    console.log(registrationmcode);

    var data = JSON.stringify({
      MCODE: registrationmcode,
      QRCodePath: thisQrcode,
      strCompanyName: "AccexRegister",
      ClientName: registrationDetailsForQR.name,
      strTicketID: registrationDetailsForQR.strRegistrationCode,
    });

    var config = {
      method: "post",
      url: URL_GET_QR_PDF,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        window.open(response.data.data, "_blank");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const hidepopup = () => {
    setRegshowpopup(false);
    setRegPrinter(false);
  };

  const closecamera = () => {
    setcamerapopup(false);
  };

  const closeregcamera = () => {
    setregisPopup(false);
  };
  // ============

  const reactPrintRegistration = async () => {
    console.log(registrationmcode);
    var data = JSON.stringify({
      Media_ID: registrationmcode,
    });

    var config = {
      method: "post",
      url: URL_GET_PROFILE_PHOTO,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios(config)
      .then(function (response) {
        console.log(response.data.data[0].path);
        setcapturedRegImage(response.data.data[0].path);
        setRegPrinter(true);
      })
      .catch(function (error) {
        console.log(error);
      });
    setRegPrinter(true);
    sethideprintbtn(true);
  };

  const handleIfChecked = (e) => {
    setprintwithImg(e.target.checked);
    console.log(e.target.checked);
    if (printwithImg) {
      setAskForImage(false);
    } else {
      setAskForImage(true);
    }
  };

  const handleRegCheck = (e) => {
    setprintwithImg(e.target.checked);
    if (printwithImg) {
      setAskForImage(false);
    } else {
      setAskForImage(true);
    }
  };

  return (
    <>
      <Navbar />
      <Leftnav />
      {alert ? <Alert className="top animating" msg={alertmsg} /> : ""}
      <div className="main">
        {/* Booking section */}
        <center>
          <h5 className="pagenames orgrank animatingLeft">OnSpot booking</h5>
        </center>
        <br />
        {isBookLoading ? (
          <Loader style={{ "margin-top": "50px" }} />
        ) : (
          <div className="dashtbl onspottbl">
            <DataGrid
              rows={tableData}
              columns={columns}
              pageSize={4}
              getRowId={(row) => row.sno}
              components={{
                Toolbar: GridToolbarExport,
              }}
              // checkboxSelection
              onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRows = tableData.filter((row) =>
                  selectedIDs.has(row.sno)
                );

                console.log(selectedRows);
                setSelectedRows(selectedRows);
                // setfirstname(selectedRows.mcode)
              }}
              {...tableData}
            />
            {showpopup ? (
              <div className="popupbg">
                <div className="userpopup animatingTop">
                  <p
                    className="cross mb"
                    style={{ zIndex: "999" }}
                    onClick={() => setshowpopup(false)}
                  >
                    <ion-icon name="close-outline"></ion-icon>
                  </p>
                  <br />
                  <br />
                  {selectedRows?.map((item) => (
                    <>
                      <div className="popupdata animatingBottom">
                        <div className="qrdivMobile">
                          <img
                            className="animatingBottom"
                            style={{ padding: "20px" }}
                            src={item.qrCodePath}
                          />
                          <br />
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            ) : (
              " "
            )}
          </div>
        )}

        {printPopup ? (
          <>
            <div className="userpopup animatingTop">
              <p
                className="cross mb"
                style={{ zIndex: "999" }}
                onClick={closesetprintPopup}
              >
                <ion-icon name="close-outline"></ion-icon>
              </p>

              <div className="popupdata" style={{ padding: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "90%",
                  }}
                >
                  <label>Company:</label>
                  <input
                    type="text"
                    placeholder="Enter Company"
                    value={askcompany}
                    onChange={(e) => setaskcompany(e.target.value)}
                  />
                </div>
                <br />
                <input
                  type="checkbox"
                  id="isPhoto"
                  checked={printwithImg}
                  onChange={(e) => handleIfChecked(e)}
                />
                <label>Photo Capture:</label>
                {printwithImg ? (
                  <>
                    <input
                      type="file"
                      placeholder="Upload Photo"
                      onChange={(e) => handlePhotoPrint(e)}
                    />
                    <button
                      style={{
                        background: "#016fe8",
                        border: "2px solid #016fe8",
                        color: "#fff",
                        padding: "2px 6px",
                        position: "absolute",
                        right: "8%",
                      }}
                      onClick={UploadSelectedPhoto}
                      disabled={uploadfiledisable}
                    >
                      upload
                    </button>
                    <p
                      style={{
                        display: "inline-block",
                        transform: "scale(1.5)",
                      }}
                      onClick={captureimage}
                    >
                      {" "}
                      <ion-icon name="camera-outline"></ion-icon>
                    </p>
                  </>
                ) : (
                  ""
                )}
                {/* {
            capturedImage === "" ? "":<img style={{height:"55px"}} src={capturedImage}  />
          } */}
                {Printer ? (
                  <Print
                    type="reg"
                    pic={capturedImage}
                    isImg={printwithImg}
                    qr={printingArr.qrCodePath}
                    name={printingArr.clientName}
                    company={askcompany}
                    ticket={printingArr.strTicketID}
                    event={printingArr.eventRowpos}
                    tckqty={printingArr.pcQuantity}
                    tckprice={printingArr.balanceAmount}
                  />
                ) : (
                  ""
                )}
                {/* <img style={{height:"55px"}} src={capturedImage}  /> */}
              </div>
              {hideprintbtn ? (
                ""
              ) : (
                <div style={{ textAlign: "center" }}>
                  <Button
                    style={{ background: "green" }}
                    variant="contained"
                    color="primary"
                    disabled={AskForImage}
                    onClick={handlereactPrint}
                  >
                    Print Ticket
                  </Button>
                </div>
              )}
            </div>
          </>
        ) : (
          ""
        )}
        {camerapopup ? (
          <>
            <div className="userpopup animatingTop">
              <p
                className="cross mb "
                id="camerapop"
                style={{ display: "inline-flex", zIndex: "999" }}
                onClick={closecamera}
              >
                <ion-icon name="close-outline"></ion-icon>
              </p>

              <div className="popupdata" style={{ padding: "20px" }}>
                <Camera upload={setAskForImage} mcode={printingArr.party} />
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        {/* registration section */}
        <center>
          <h5 className="pagenames orgrank animatingLeft">
            OnSpot Registration
          </h5>
        </center>
        <br />
        {isRegLoading ? (
          <Loader style={{ "margin-top": "50px" }} />
        ) : (
          <div className="dashtbl onspottbl">
            <DataGrid
              rows={registrationArr}
              columns={registrationcolumns}
              pageSize={5}
              getRowId={(row) => row.rowpos}
              components={{
                Toolbar: GridToolbarExport,
              }}
              {...tableData}
            />
          </div>
        )}

        {Regshowpopup ? (
          <>
            <div className="userpopup">
              <div className="popupdata animatingBottom">
                <p
                  style={{ color: "white" }}
                  className="cross"
                  onClick={hidepopup}
                >
                  <ion-icon name="close-outline"></ion-icon>
                </p>
                <div className="pop-qr-img">
                  <img style={{}} src={thisQrcode} />
                  <br />
                  <input type="hidden" value={registrationmcode} />
                </div>
                <center>
                  <div className="pop-qr-center">
                    <input
                      type="checkbox"
                      id="isPhoto"
                      checked={printwithImg}
                      onChange={(e) => handleRegCheck(e)}
                    />
                    <label>Photo Capture:</label>
                  </div>
                  <div className="pop-qr-img">
                    {printwithImg ? (
                      <>
                        <input
                          type="file"
                          placeholder="Upload Photo"
                          onChange={(e) => handlePhotoPrint(e)}
                        />
                        <button
                          onClick={UploadSelectedPhoto}
                          disabled={uploadfiledisable}
                        >
                          upload
                        </button>
                        <p className="cam-icon" onClick={handleRegisterCamera}>
                          {" "}
                          <ion-icon name="camera-outline"></ion-icon>
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </center>

                {hideprintbtn ? (
                  ""
                ) : (
                  <div style={{ textAlign: "center" }}>
                    {/* <Button style={{background:"#016fe8",border:"2px solid #016fe8",color:"#fff",padding:"5px"}} onClick={printThisQR}>Print QR</Button> */}
                    <Button
                      style={{ background: "green" }}
                      variant="contained"
                      color="primary"
                      disabled={AskForImage}
                      onClick={reactPrintRegistration}
                    >
                      Print Ticket
                    </Button>
                  </div>
                )}

                {RegPrinter ? (
                  <Print
                    type="reg"
                    pic={capturedRegImage}
                    isImg={printwithImg}
                    qr={thisQrcode}
                    name={
                      thisRegData.name +
                      (thisRegData.surname ? " " + thisRegData.surname : "")
                    }
                    company={thisRegData.companyOrganisation}
                    ticket={thisRegData.strRegistrationCode}
                    event={thisRegData.eventID}
                    tckqty={thisRegData.pcQuantity}
                    tckprice={thisRegData.balanceAmount}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        {regisPopup ? (
          <>
            <div className="userpopup animatingTop">
              <p
                className="cross mb "
                id="camerapop"
                style={{ display: "inline-flex", zIndex: "999" }}
                onClick={closeregcamera}
              >
                <ion-icon name="close-outline"></ion-icon>
              </p>

              <div className="popupdata" style={{ padding: "20px" }}>
                <Camera upload={setAskForImage} mcode={registrationmcode} />
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default OnSpotBooking;
